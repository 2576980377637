html,
body,
#root {
  height: 100%;
}

div[data-live-announcer='true'] {
  margin: 0 !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
}

#HW_badge_cont {
  position: absolute !important;
  top: 0 !important;
  right: 0.5rem !important;
}

#HW_badge {
  border: 2px solid white !important;
  line-height: normal !important;
  background-color: rgb(244, 101, 50) !important;
  color: white !important;
  width: 1rem !important;
  height: 1rem !important;
}

#HW_frame_cont {
  border-radius: 1rem !important;
}

.reactMarkDown > p {
  margin: 0 !important;
}

.custom-scrollbar {
  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 0.6rem;
  }
  &::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(193, 193, 193);
    border-radius: 2rem;
    border: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(173, 173, 173);
  }
  &::-webkit-scrollbar:horizontal {
    height: 0.6rem;
  }
  /* Firefox */
  @supports (-moz-appearance: none) {
    scrollbar-width: thin;
  }
}
